import { refinementList } from "instantsearch.js/es/widgets";
import I18n from "../../../../app/javascript/i18n";

const filters = {
  programs: {
    title: I18n.t("search.filters.programs"),
    widget: (container) =>
      refinementList({
        attribute: "programs",
        container,
      }),
  },
  tags: {
    title: I18n.t("search.filters.tags.text"),
    widget: (container) =>
      refinementList({
        attribute: "tags.text",
        container,
      }),
  },
  year: {
    title: I18n.t("search.filters.year"),
    widget: (container) =>
      refinementList({
        attribute: "year",
        limit: 5,
        showMore: true,
        sortBy: ["name:desc"],
        templates: {
          showMoreText: `
            {{#isShowingMore}}
              ${I18n.t("search.ui.see_less")}
            {{/isShowingMore}}
            {{^isShowingMore}}
              ${I18n.t("search.ui.see_more")}
            {{/isShowingMore}}
          `,
        },
        container,
      }),
  },
  human_kind: {
    title: I18n.t("search.filters.human_kind"),
    widget: (container) =>
      refinementList({
        attribute: "human_kind",
        container,
      }),
  },
};

export default {
  content: [filters.human_kind],
  events: [
    {
      title: I18n.t("search.filters.event_type"),
      widget: (container) =>
        refinementList({
          attribute: "event_type",
          sortBy: ["name:desc"],
          container,
        }),
    },
    filters.year,
    {
      title: I18n.t("search.filters.place"),
      widget: (container) =>
        refinementList({
          attribute: "place",
          searchable: true,
          searchablePlaceholder: I18n.t("search.ui.place_placeholder"),
          container,
        }),
    },
    filters.programs,
  ],
  indicators: [
    {
      title: false,
      widget: (container) =>
        refinementList({
          attribute: "categories",
          sortBy: ["name:asc"],
          container,
        }),
    },
  ],
  news_flashes: [filters.year, filters.tags],
  pages: [filters.human_kind],
  people: [],
  publications: [
    {
      title: I18n.t("search.filters.publication_type"),
      widget: (container) =>
        refinementList({
          attribute: "category",
          limit: 15,
          sortBy: ["name:asc"],
          container,
        }),
    },
    filters.year,
    filters.programs,
    {
      title: I18n.t("search.filters.authors.name"),
      widget: (container) =>
        refinementList({
          attribute: "authors.name",
          searchable: true,
          searchablePlaceholder: I18n.t("search.ui.authors_placeholder"),
          container,
        }),
    },
  ],
  trainings: [
    filters.tags,
    {
      title: I18n.t("search.filters.formats"),
      widget: (container) =>
        refinementList({
          attribute: "formats",
          container,
        }),
    },
    {
      title: I18n.t("search.filters.temporality"),
      widget: (container) =>
        refinementList({
          attribute: "temporality",
          sortBy: ["name:desc"],
          container,
        }),
    },
    {
      title: I18n.t("search.filters.place"),
      widget: (container) =>
        refinementList({
          attribute: "place",
          searchable: true,
          searchablePlaceholder: I18n.t("search.ui.place_placeholder"),
          container,
        }),
    },
  ],
};
